$enable-responsive-font-sizes: true;

$primary: #ff6768;
$secondary: #314364;
$dark: #17223b;

.text-dark2 {
  color: #263859;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat|Raleway:700,800&display=swap");

$font-family-sans-serif: "Montserrat", sans-serif !important;

html {
  scroll-behavior: smooth;
}

.custom-mt {
  margin-top: 7em !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Raleway", sans-serif !important;
  font-weight: "bold" !important;
  color: $secondary !important;
}

@import "~bootstrap/scss/bootstrap.scss";

.blink {
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}
@-webkit-keyframes blink {
  50% {
    visibility: hidden;
  }
}
@keyframes blink {
  50% {
    visibility: hidden;
  }
}

.skill-box {
  position: relative;
}

.skill {
  transition: transform 0.2s; /* Animation */
}

.skill:hover {
  transform: scale(1.05);
}

.hero {
  background-color: #263859;
  position: relative;
  height: 40vh;
  overflow: hidden;
}

.hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.cube {
  position: absolute;
  top: 30vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px lighten(#263859, 8%);
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;

  &:nth-child(2n) {
    border-color: lighten(#263859, 10%);
  }

  &:nth-child(2) {
    animation-delay: 2s;
    left: 25vw;
    top: 20vh;
  }

  &:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 25vh;
  }

  &:nth-child(4) {
    animation-delay: 6s;
    left: 90vw;
    top: 5vh;
  }

  &:nth-child(5) {
    animation-delay: 8s;
    left: 10vw;
    top: 42vh;
  }

  &:nth-child(6) {
    animation-delay: 10s;
    left: 50vw;
    top: 5vh;
  }
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: absolute;
  font-size: 1.5em;
  top: 0em;
  right: 0em;
}

.custom-col {
  width: 15%;
}
